import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { UsePageContext } from "../context/page-context"

const Root = styled.footer`
  // 白いマージンができるのを防止
  /* margin-bottom: -56px; */
  background-color: #000000;
  color: #ffffff;
  display: flex;
  padding: 40px 24px 80px 24px;
`

const RootAtNotContactPage = styled(Root)`
  // 白いマージンができるのを防止
  height: 340px;
  justify-content: center;
  @media screen and (max-width: 799px) {
    height: auto;
    justify-content: start;
    padding: 40px 24px 62px 24px;
  }
`

const RootAtContactPage = styled(Root)`
  padding: 40px 24px;
  justify-content: space-between;
  align-items: center;
  /* @media screen and (max-width: 799px) {
    height: auto;
    justify-content: start;
    padding: 40px 24px 62px 24px;
  } */
`

const Container = styled.div`
  max-width: 1079px;
  display: flex;
  .column:last-child {
    padding-right: 17px;
    width: auto;
  }
  @media screen and (max-width: 799px) {
    flex-direction: column;
    .column:last-child {
      border-bottom: 0;
    }
  }
`

const Column = styled.div`
  width: 328px;
  height: 220px;
  padding: 0 24px 0 17px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  border-left: 1px solid #666666;
  /* @media screen and (max-width: 1079px) {
    width: calc(100% / 3);
  } */
  @media screen and (max-width: 799px) {
    width: 100%;
    margin-bottom: 60px;
    border-left: 0;
    border-bottom: 1px solid #666666;
  }
`

const Title = styled.div`
  /* width: 240px; */
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  padding-bottom: 15px;
  /* &:hover {
    text-decoration: underline;
    text-decoration-color: #ffffff;
    text-decoration-thickness: 1px;
  }
  &:active {
    text-decoration: underline;
    text-decoration-color: #CCCCCC;
    text-decoration-thickness: 1px;
  } */
`

const Description = styled.div`
  max-width: 328px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: justify;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(props => <Link {...props} />)`
  /* width: 240px; */
  /* margin-right: 24px; */
  font-size: 14px;
  letter-spacing: 0;
  line-height: 32px;
  color: #ffffff;
  &:hover {
    text-decoration: underline;
    text-decoration-color: #ffffff;
    text-decoration-thickness: 1px;
  }
  &:active {
    text-decoration: underline;
    text-decoration-color: #cccccc;
    text-decoration-thickness: 1px;
  }
`

const StyledSmallLink = styled(props => <Link {...props} />)`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  height: 18px;
  color: #ffffff;
  &:hover {
    text-decoration: underline;
    text-decoration-color: #ffffff;
    text-decoration-thickness: 1px;
  }
  &:active {
    text-decoration: underline;
    text-decoration-color: #cccccc;
    text-decoration-thickness: 1px;
  }
`

const Text = styled.span`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: right;
`

const Footer = () => {
  const { isAtContactPage, isAtNotfoundPage } = UsePageContext()
  const year = new Date().getFullYear()

  return (
    <>
      {!isAtContactPage && !isAtNotfoundPage && <NormalFooter />}
      {isAtContactPage && !isAtNotfoundPage && (
        <RootAtContactPage>
          <StyledSmallLink to="/privacy">プライバシーポリシー</StyledSmallLink>
          <Text>{`@${year} 株式会社アクア`}</Text>
        </RootAtContactPage>
      )}
    </>
  )
}

const NormalFooter = () => {

  const DescriptionQualifications = styled(Description)`
    padding-top: 4px;
    line-height: 28px;
  font-size: 13px;
  `

  const Qualifications = styled.ul`
    margin-bottom: 0;
  `

  const Qualification = styled.li`
    margin-bottom: 0;
    word-break: break-all;
  `

  return (
    <RootAtNotContactPage>
      <Container>
        <Column className="column">
          <Title>株式会社アクア</Title>
          <Description>
            ローコード開発ツールGeneXusやクラウド環境Azureを組み合わせ、変化に強い高速開発をワンストップで実現。株式会社アクアは、お客様との対話を大事にするシステム開発会社です。
          </Description>
        </Column>
        <Column className="column">
          <Title>各種認証</Title>
          <DescriptionQualifications>
            <Qualifications>
              <Qualification><StyledLink to="/iso9001">JIS Q 9001:2015 (ISO 9001:2015)</StyledLink></Qualification>
              <Qualification><StyledLink to="/iso27001">JIS Q 27001:2014 (ISO/IEC 27001:2013)</StyledLink></Qualification>
              <Qualification>Microsoft Gold コンピテンシー</Qualification>
              <Qualification>GeneXus Software Development Partner</Qualification>
              <Qualification>総務省電気通信事業者（届出番号：A-01-17736 届出年月日：2020/3/18）</Qualification>
            </Qualifications>
          </DescriptionQualifications>
          {/* <Description style={{paddingTop: "4px", lineHeight: "28px"}}>
          • &nbsp;&nbsp;	ISO/IEC27001取得 <br/>
          • &nbsp;&nbsp;	ISO9001取得 <br/>
          • &nbsp;&nbsp;	Microsoftゴールドパートナー <br/>
          • &nbsp;&nbsp;	GeneXusパートナー <br/>
          • &nbsp;&nbsp;	総務省電気通信事業者
        </Description> */}
        </Column>
        <Column className="column">
          <Title>サイトメニュー</Title>
          <Links>
            <StyledLink to="/">ホーム</StyledLink>
            <StyledLink to="/strength/">アクアの強み</StyledLink>
            <StyledLink to="/cases/">事例</StyledLink>
            <StyledLink to="/topics/">トピックス</StyledLink>
            <StyledLink to="/aboutus/">会社情報</StyledLink>
          </Links>
        </Column>
      </Container>
      {/* <Bottom>
              <InquiryButton></InquiryButton>
          </Bottom> */}
    </RootAtNotContactPage>
  )
}

export default Footer
