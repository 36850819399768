import React from "react"
import styled from "@emotion/styled"
import Icon from "../images/svg/icon-contact.svg"

const StyledButton = styled.button`
  height: 40px;
  width: 136px;
  border-radius: 8px;
  padding: 12px 12px 14px 10px;
  font-weight: bold;
  letter-spacing: 0.32px;
  line-height: 14px;
  background-color: #ef3c66;
  /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15); */
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #EB1244
  }
  &:active {
    background-color: #DC1946;
  } 
  @media screen and (max-width: 799px) {
    display: none;
  }
`

const InquiryButton = () => {
  return (
    <StyledButton name="お問い合わせ">
      <Icon
        style={{
          height: 24,
          marginRight: 4,
          fill: "#ffffff",
        }}
      />
      <p
        style={{
          margin: "unset",
          fontSize: 14,
        }}
      >
        対話を始める
      </p>
    </StyledButton>
  )
}

export default InquiryButton
