exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-cases-js": () => import("./../../../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iso-27001-js": () => import("./../../../src/pages/iso27001.js" /* webpackChunkName: "component---src-pages-iso-27001-js" */),
  "component---src-pages-iso-9001-js": () => import("./../../../src/pages/iso9001.js" /* webpackChunkName: "component---src-pages-iso-9001-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-strength-js": () => import("./../../../src/pages/strength.js" /* webpackChunkName: "component---src-pages-strength-js" */),
  "component---src-pages-topics-js": () => import("./../../../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */),
  "component---src-templates-devevelopment-case-js": () => import("./../../../src/templates/devevelopment-case.js" /* webpackChunkName: "component---src-templates-devevelopment-case-js" */),
  "component---src-templates-topic-details-js": () => import("./../../../src/templates/topic-details.js" /* webpackChunkName: "component---src-templates-topic-details-js" */)
}

