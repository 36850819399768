import React from "react"
import styled from "@emotion/styled"
import { Link, useStaticQuery, graphql} from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Icon from "../images/svg/icon-contact.svg"
import { UsePageContext } from "../context/page-context"

const PhotoContainer = styled.div`
    box-sizing: border-box;
    height: 74px;
    width: 74px;
    border: 3px solid #404CA9;
    border-radius: 191px;
    background-color: #D8D8D8;
    box-shadow: 0 0 50px 0 rgba(0,0,0,0.15);
    margin-top: 10px;
    opacity: 0;
    transform: translateY(74px);
    transition: all 1s ease;
    @media screen and (max-width: 799px) {
    display: none;   
  }
`

const StyledButton = styled.button`
  position: relative;
  height: 40px;
  width: 142px;
  border-radius: 8px;
  font-size: 14px;
  padding: 12px 16px 14px 12px;
  font-weight: bold;
  letter-spacing: 0.32px;
  line-height: 14px;
  background-color: #ef3c66;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  transform: translateY(74px);
  transition: all 1s ease;
  @media screen and (max-width: 799px) {
    width: 52px;
    height: 52px;
    border-radius: 26px;
    padding: 12px 12px 12px 12px;
    transform: translateY(0px);
  }
  &:hover {
    background-color: #EB1244;
    transform: scale(1.1);
    &:before {
      border-bottom-color: #EB1244;
    }
  }

  &:active {
    background-color: #DC1946;
    &:before {
      border-bottom-color: #DC1946;
    }
  }
  &:before {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 61px;
    height: 10px;
    width: 20px;
    transform: rotate(-180deg);
    border-bottom: 10px solid #ef3c66;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    @media screen and (max-width: 799px) {
      width: 12px;
      height: 12px;
      bottom: -9px;
      left: 16px;
    }
  }
`

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  @media screen and (max-width: 799px) {
    height: 28px;
    width: 28px;
  }
`

const Text = styled.p`
  padding-left: 4px;
  margin: 0;
  font-size: 14px;
  @media screen and (max-width: 799px) {
    display: none;
  }
`

const Container = styled.div`
  width: 142px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  &:hover ${PhotoContainer} {
    transform: translateY(0px);
    opacity: 1;
    @media screen and (max-width: 799px) {
      opacity: 0;
    }
  };
  &:hover ${StyledButton} {
    transform: translateY(0px);
  }
  @media screen and (max-width: 799px) {
    width: 52px;
    height: 62px;
  }
`

const InquiryButtonTail = () => {
  const {isAtContactPage} = UsePageContext()

  const data = useStaticQuery(graphql`
        query inquiryPhotoQuery {
          allContentfulFacePhoto(sort: {fields: order}) {
            edges {
              node {
                name
                order
                photo {
                  gatsbyImageData(
                    width: 68
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
    `)
    const facePhotos = data.allContentfulFacePhoto.edges
    const totalNum = facePhotos.length
    const today = new Date();
    const index = today.getDate() % totalNum;
    const photo = getImage(facePhotos[index].node.photo)

  return (
    <>
      {
        !isAtContactPage &&
        <Link to="/contact" aria-label="contact us">
          <Container>
            <StyledButton name="お問い合わせ">
              <IconContainer>
                <Icon
                  style={{
                    height: "100%",
                    fill: "#ffffff"
                  }}
                />
              </IconContainer>
              <Text>対話を始める</Text>     
            </StyledButton>
            <PhotoContainer>
              <GatsbyImage 
                image={photo} 
                alt='問い合わせ' 
                style={{borderRadius: 191, height: "100%"}}
                imgStyle={{borderRadius: 191}}/>
            </PhotoContainer> 
          </Container>
        </Link> 
      }
      {
        isAtContactPage && <></>
      }
    </>
  )
}

export default InquiryButtonTail
