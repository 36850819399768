import React from "react"
import { UsePageContext } from "../context/page-context"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import InquiryButton from "./inquiry-button"
import Hamburger from "../images/svg/menu_24px_rounded.svg"
import Close from "../images/svg/close_black_24dp.svg"
import { StaticImage } from "gatsby-plugin-image"
// import Logo from "../images/svg/logo.svg"

const Root = styled.header`
  /* width: 100vw; */
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 13px 24px 11px 24px;
  @media screen and (max-width: 799px) {
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8); /* 背景のみ半透明 */
  }
`

const Left = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Right = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 799px) {
    
  }
`
const StyledLink = styled(props => <Link {...props} />)`
  margin-right: 24px;
  font-size: 14px;
  color: #222222;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 15px;
  text-align: center;
  &:hover {
    color: #3D7CD1;
  }
  &:active {
    color: #4A76B2;
  }
  @media screen and (max-width: 799px) {
    display: none;
  }
`

const TopLink = styled(props => <Link {...props} />)`
  height: 40px;
  width: 138px;
`

const StyledButton = styled.button`
  display: none;
  border: none;
  width: 24px;
  height: 24px;
  background-color: transparent;
  padding: 0;
  @media screen and (max-width: 799px) {
    display: block;
  }
`

const Header = ({ showMobileMenu, mobileMenuShown }) => {

  const togggleMenu = () => {
    showMobileMenu(!mobileMenuShown)
  }

  // const closeMobileMenu = () => {
  //   showMobileMenu(false)
  // }

  const { isAtContactPage, isAtNotfoundPage } = UsePageContext()
  const Icon = mobileMenuShown ?  <Close></Close> : <Hamburger></Hamburger>

  return (
    <Root>
      <Left>
        <TopLink to="/">          
          {/* <Logo /> */}
          <StaticImage 
            src="../images/logo.png"
            placeholder="none"
            // layout="fixed"
            alt="ロゴ"
            width={138}
            height={40}
          />
        </TopLink>
      </Left>
      {
        (!isAtContactPage && !isAtNotfoundPage) &&       
        <Right>
          <StyledLink to="/strength/">アクアの強み</StyledLink>
          <StyledLink to="/cases/">事例</StyledLink>
          <StyledLink to="/topics/">トピックス</StyledLink>
          <StyledLink to="/aboutus/">会社情報</StyledLink>
          <Link to="/contact">
            <InquiryButton />
          </Link>
          <StyledButton　onClick = {() => togggleMenu()} name="メニュー">
            {Icon}
          </StyledButton>
      </Right>
      }
    </Root>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  showMobileMenu: PropTypes.func,
  mobileMenuShown: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
