import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const MobileMenu = ({ showMobileMenu, mobileMenuShown }) => {
  const Root = styled.div`
    padding: 40px 0 0 0;
    margin-top: 77px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    animation-name: fadeIn;
    animation-duration: 0.8s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        transform: translateX(-20px);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  `

  const StyledLink = styled(props => <Link {...props} />)`
    font-size: 18px;
    color: #222222;
    font-weight: 500;
    letter-spacing: 0.42px;
    line-height: 18px;
    text-align: center;
    // Chrome のiOS, Safariだと親要素のrow-gapが効かない
    margin-bottom: 30px;
    &:hover {
      color: #3d7cd1;
    }
    &:active {
      color: #4a76b2;
    }
  `

  const togggleMenu = () => {
    showMobileMenu(!mobileMenuShown)
  }

  return (
    <Root>
      <StyledLink to="/strength/" onClick={() => togggleMenu()}>
        アクアの強み
      </StyledLink>
      <StyledLink to="/cases/" onClick={() => togggleMenu()}>
        事例
      </StyledLink>
      <StyledLink to="/topics/" onClick={() => togggleMenu()}>
        トピックス
      </StyledLink>
      <StyledLink to="/aboutus/" onClick={() => togggleMenu()}>
        会社情報
      </StyledLink>
      <StyledLink to="/contact/" onClick={() => togggleMenu()}>
        お問い合わせ
      </StyledLink>
    </Root>
  )
}

export default MobileMenu
