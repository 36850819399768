/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useState, useEffect, useRef, memo } from "react"
import PropTypes, { string } from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Header from "./header"
import Footer from "./footer"
import InquiryButtonTail from "./inquiry-button-tail.js"
import MobileMenu from "./mobile-menu"
import "./layout.css"
import { PageContextProvider } from "../context/page-context.js"
import useMediaQuery from "@material-ui/core/useMediaQuery"

//css
const MainContainer = styled.div`
  margin: 0 auto;
  padding: 0 1.0875rem 1.45rem;
  @media screen and (max-width: 799px) {
    padding-top: 64px;
    + .inquiry {
      opacity: 1 !important;
    }
  }
`

const Inquiry = styled.div`
  max-width: 100%;
  display: flex;
  transition: 0.3s;
  justify-content: flex-end;
  bottom: 26px;
  right: 24px;
  position: fixed;
`

//
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const title = data.site.siteMetadata?.title
  const isWide = useMediaQuery("(min-width: 799px)", { noSsr: true })

  return (
    <PageContextProvider>
      {isWide && <LayoutDesktop title={title} children={children} />}
      {!isWide && <LayoutMobile title={title} children={children} />}
    </PageContextProvider>
  )
}

const MainContents = memo(props => {
  const headerShown = props.headerShown
  const children = props.children

  return (
    <>
      <MainContainer>
        <main>{children}</main>
      </MainContainer>

      <Inquiry className="inquiry" style={{ opacity: headerShown ? 0 : 1 }}>
        <InquiryButtonTail />
      </Inquiry>
      {/* スマホでハンバーガーアイコン押下時のメニュー下にフッター表示しないので、ここに配置している */}
      <Footer />
    </>
  )
})

const LayoutDesktop = props => {
  const title = props.title
  const children = props.children
  const [observing, setObserving] = useState(false)
  const [headerShown, setHeaderShown] = useState(true)

  const target = useRef(null)

  let options = {}

  useEffect(() => {
    if (!observing) {
      const observer = new IntersectionObserver(callback, options)
      observer.observe(target.current)
      setObserving(true)
    }
  }, [target, options])

  useEffect(() => {
    // 画面幅799px以上のデバイスでのみヘッダー表示状態かどうかを監視する
    // setCanRender(true)
    options = {
      root: document.querySelector("#gatsby-focus-wrapper"),
      threshold: 1,
    }
  }, [])

  const callback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setHeaderShown(true)
      } else {
        setHeaderShown(false)
      }
    })
  }

  return (
    <>
      <div ref={target}>
        <Header
          siteTitle={title || `Title`}
          showMobileMenu={() => {}}
          mobileMenuShown={false}
        />
      </div>
      <MainContents headerShown={headerShown} children={children} />
    </>
  )
}

const LayoutMobile = props => {
  const title = props.title
  const children = props.children

  const [mobileMenuShown, setMobileMenuShown] = useState(false)

  const showMobileMenu = () => {
    return setMobileMenuShown(!mobileMenuShown)
  }

  return (
    <>
      <div>
        <Header
          siteTitle={title || `Title`}
          showMobileMenu={() => showMobileMenu()}
          mobileMenuShown={mobileMenuShown}
        />
      </div>
      {!mobileMenuShown && (
        <MainContents headerShown={true} children={children} />
      )}
      {mobileMenuShown && (
        <MobileMenu
          showMobileMenu={() => showMobileMenu()}
          mobileMenuShown={mobileMenuShown}
        />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
