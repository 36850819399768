import { React, createContext, useState, useContext } from "react"

const PageContext = createContext({
  isAtContactPage: false,
  setIsAtContactPage: () => {},
  isAtNotfoundPage: false,
  setIsAtNotfoundPage: () => {},
})

const PageContextProvider = ({ children }) => {
  const [isAtContactPage, setIsAtContactPage] = useState(false)
  const [isAtNotfoundPage, setIsAtNotfoundPage] = useState(false)

  return (
    <PageContext.Provider
      value={{
        isAtContactPage,
        setIsAtContactPage,
        isAtNotfoundPage,
        setIsAtNotfoundPage,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

const UsePageContext = () => useContext(PageContext)

export { PageContextProvider, UsePageContext }
